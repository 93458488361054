import { render, staticRenderFns } from "./UserProfileModal.vue?vue&type=template&id=09989242&scoped=true"
import script from "./UserProfileModal.vue?vue&type=script&lang=js"
export * from "./UserProfileModal.vue?vue&type=script&lang=js"
import style0 from "./UserProfileModal.vue?vue&type=style&index=0&id=09989242&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09989242",
  null
  
)

export default component.exports