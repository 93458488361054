import { render, staticRenderFns } from "./ComponentAttendees.vue?vue&type=template&id=56d7becc&scoped=true"
import script from "./ComponentAttendees.vue?vue&type=script&lang=js"
export * from "./ComponentAttendees.vue?vue&type=script&lang=js"
import style0 from "./ComponentAttendees.vue?vue&type=style&index=0&id=56d7becc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d7becc",
  null
  
)

export default component.exports